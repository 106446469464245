import settings from '@locale-config';
import { UnifiedPlacementConfig, UnifiedSetupConfig } from './types';

const { isBread } = settings;

declare global {
  interface Window {
    BreadPayments?: {
      registerPlacements(placementConfigs: UnifiedPlacementConfig[]): void;
      setup(config: UnifiedSetupConfig): void;
      // More methods exist: https://platform-docs.breadpayments.com/bread-onboarding/docs/methods
    };
  }
}

export const placementConfigDefaults: Pick<UnifiedPlacementConfig, 'financingType' | 'locationType'> = {
  financingType: 'card',
  locationType: 'product',
};

export const setupLinkPrefetchListener = () => {
  // Bread should only be loaded for the US market!
  if (!isBread) {
    return;
  }
  const preloadBreadSDK = () => {
    const link = document.createElement('link');
    link.setAttribute('rel', 'preload');
    link.setAttribute('as', 'script');
    link.setAttribute('href', 'https://connect.breadpayments.com/unified.sdk.js');
    document.head.appendChild(link);
  };
  const link = document.querySelector('.pip-financial-service--new');
  // If hover on link, call prefetch
  link?.addEventListener('mouseover', () => {
    preloadBreadSDK();
  });
};

const loadBreadSDK = (breadKey : string) => new Promise((resolve) => {
  // Bread should only be loaded for the US market!
  if (!isBread) {
    return;
  }

  const sdkScript = document.createElement('script');
  sdkScript.setAttribute('data-bread-payments-key', breadKey);
  sdkScript.setAttribute('async', 'true');
  sdkScript.setAttribute('src', 'https://connect.breadpayments.com/unified.sdk.js');
  sdkScript.addEventListener('load', resolve);
  document.head.appendChild(sdkScript);
});

// Destructor, can for instance be used in a hook
export const unloadBreadSDK = () => {
  // Bread should only be loaded for the US market!
  if (!isBread) {
    return;
  }

  const sdkScript = document.querySelector('script[data-bread-payments-key]');
  sdkScript?.remove();
  delete window.BreadPayments;
};

export const initializeScripts = (placementConfigs: UnifiedPlacementConfig[]) => {
  // Bread should only be loaded for the US market!
  if (!isBread) {
    return;
  }

  // Configure BreadPayments
  if (window.BreadPayments) {
    window.BreadPayments.setup({
      loyaltyID: '',
      storeNumber: '159',
      env: import.meta.env.VITE_APP_PUBLIC_PROD ? 'PROD' : 'STAGE', // Must be PROD or STAGE
    });
    // Register Placements
    window.BreadPayments.registerPlacements(placementConfigs);
  } else {
    // eslint-disable-next-line no-console
    console.warn('window.BreadPayments not loaded');
  }
};

export default loadBreadSDK;
