import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { Translations } from 'adapters/fs-attract/translations';
import translate from 'utils/translate';

// Create context with default translation value
export const TranslationContext = createContext<Translations | undefined>(undefined);

const useTranslate = () => {
  const translations = useContext<Translations | undefined>(TranslationContext);
  return (
    key: string,
    replacers?: { [key: string]: string | number },
  ) => {
    if (!translations) {
      // This occurs before the translations have been fetched
      return '';
    }
    if (!replacers) {
      // Nothing to interpolate!
      return translations[key];
    }
    return translate(translations, key, replacers);
  };
};

export default useTranslate;
