import { AttractDataResponseV2, Services } from '../adapters/fs-attract/types';

const attractMVPEndpoint = import.meta.env.VITE_APP_API_ATTRACT_URL_MVP;
const locale = import.meta.env.VITE_APP_LOCALE.toLowerCase();

const getAttractDataMVP = async (
  amount: number,
): Promise< AttractDataResponseV2 | null> => {
  if (typeof amount !== 'number' || !amount) throw Error('Amount must be a positive number');
  const apiUrl = `${attractMVPEndpoint}/v1/${locale}/${amount}`;
  const res = await fetch(apiUrl);
  if (!res.ok) {
    throw Error('Could not get hyperlink from the API');
  }

  try {
    const data = await res.json();
    return data.services.map((service: Services, index: number) => ({
      isWithinRange: false,
      indexPosition: index,
      mode: 'link',
      icon: {
        type: 'skapa',
        source: 'financing',
      },
      heading: null,
      text: service.text,
      linkText: service.link.title,
      productID: null,
      href: service.link.url,
    }));
  } catch (error) {
    throw new Error('Error parsing response as JSON');
  }
};

export default getAttractDataMVP;
