const listOfLocalesForMVP: string[] = [
  'cz/cs',
  'cz/en',
  'hr/hr',
  'hu/hu',
  'ie/en',
  'jp/en',
  'jp/ja',
  'kr/ko',
  'nl/en',
  'nl/nl',
  'ro/ro',
  'rs/sr',
  'si/sl',
  'sk/sk',
];

export default listOfLocalesForMVP;
