import { render } from 'preact';
import { StrictMode } from 'preact/compat';
import getAttractData from 'public-api/getAttractData';
import getAttractDataV2 from 'public-api/getAttractDataV2';
import { getData, getUsData } from 'adapters/fs-attract/getData';
import settings from '@locale-config';
import { TranslationContext } from 'hooks/useTranslate';

type ModalPayload = {
  amount: number,
  productId?: string,
};

type ModalPayloadError = {
  message: string,
};

const openModal = async ({ amount, productId }: ModalPayload) => {
  const appRoot = document.querySelector('#ao-app');
  if (appRoot && amount) {
    const destroy = () => render(null, appRoot);
    if (settings.isBread) {
      if (productId === 'afterpay') {
        const [
          { default: AfterpayModal },
        ] = await Promise.all([
          import('./components/AfterpayModal/AfterpayModal'),
          getUsData(),
        ]);
        try {
          render(
            <StrictMode>
              <AfterpayModal onClose={destroy} />
            </StrictMode>,
            appRoot,
          );
        } catch (err) {
          throw Error('Not able to render AfterpayModal component');
        }
        return;
      }

      const [
        { default: BreadModal },
        [products, translations, error],
      ] = await Promise.all([
        import('./components/BreadModal/BreadModal'),
        getUsData(),
      ]);
      if (error) {
        throw Error(error);
      }
      if (!translations) {
        throw Error('Missing translations');
      }
      if (!products) {
        throw Error('Missing products');
      }

      if (products) {
        try {
          render(
            <StrictMode>
              <TranslationContext.Provider value={translations}>
                <BreadModal
                  amount={amount}
                  products={products}
                  onClose={destroy}
                />
              </TranslationContext.Provider>
            </StrictMode>,
            appRoot,
          );
        } catch (err) {
          throw Error('Not able to render BreadModal component');
        }
      } else {
        throw Error('Not enough API data to render');
      }
    } else {
      const [
        { default: MonthlyPriceModal },
        [calculations, translations, error],
      ] = await Promise.all([
        import('./components/MonthlyPriceModal/MonthlyPriceModal'),
        getData(amount),
      ]);
      try {
        render(
          <StrictMode>
            <TranslationContext.Provider value={translations}>
              <MonthlyPriceModal
                calculationResponse={calculations}
                onClose={destroy}
              />
            </TranslationContext.Provider>
          </StrictMode>,
          appRoot,
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw Error('Not able to render MonthlyPriceModal component');
      }
    } if (!appRoot) {
      throw Error('AppRoot not found');
    }
  }
};

const setupModalListener = () => window.ikea.pubsub.subscribe<ModalPayload>(
  'ATTRACT/OPEN_MODAL',
  (payload: ModalPayload) => openModal(payload).catch(
    (error: ModalPayloadError) => window.ikea.pubsub.publish('ATTRACT/MODAL_OPEN_ERROR', { error: error?.message }),
  ),
);

export default async () => {
  window.ikea.cfs = {
    ...window.ikea.cfs,
    getAttractData,
    getAttractDataV2,
  };
  setupModalListener();
  await window.ikea.pubsub.publish('ATTRACT/ATTRACT_LOADED', {});
};
