import { Translations } from 'adapters/fs-attract/translations';

type ReplacerTuple = [string, string | number];

const translate = (
  translations: Translations,
  translationKey: string,
  replacers: { [key: string]: string | number },
) => {
  const keyValue = translations[translationKey];

  /**
   * If keyValue is null, it is intentionally left empty and should be set to ''.
   * If keyValue is '', there is no point in interpolating anything and we can exit early.
   */
  if (!keyValue) {
    return '';
  }

  const replaceAllReducer = (acc: string, [key, value]: ReplacerTuple) => acc
    .split(`{${key}}`)
    .join(String(value));

  return Object
    .entries(replacers)
    .reduce(replaceAllReducer, keyValue);
};

export default translate;
