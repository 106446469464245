import settings from '@locale-config';
import { AttractDataResponse } from './types';

const attractEndpoint = import.meta.env.VITE_APP_API_ATTRACT_URL;
const locale = import.meta.env.VITE_APP_LOCALE.toLowerCase();

const getAttractData = async (
  amount: number,
): Promise<AttractDataResponse | null> => {
  if (typeof amount !== 'number' || !amount) throw Error('Amount must be a positive number');

  const apiUrl = `${attractEndpoint}/v1/hyperlink/${locale}/${amount}`;
  const res = await fetch(apiUrl);

  if (!res.ok) {
    throw Error('Could not get hyperlink from the API');
  }

  try {
    const data = await res.json();
    return (!data.isWithinRange && settings.hideLink)
      ? null
      : data as AttractDataResponse;
  } catch (error) {
    throw new Error('Error parsing response as JSON');
  }
};
export default getAttractData;
