import settings from '@locale-config';
import { AttractDataResponseV2 } from '../adapters/fs-attract/types';
import getAttractDataMVP from './getAttractDataMVP';
import listOfLocalesForMVP from './listOfLocalesForMVP';

const attractEndpoint = import.meta.env.VITE_APP_API_ATTRACT_URL;
const locale = import.meta.env.VITE_APP_LOCALE.toLowerCase();

const getAttractDataV2 = async (
  amount: number,
): Promise<AttractDataResponseV2 | null> => {
  if (typeof amount !== 'number' || !amount) throw Error('Amount must be a positive number');

  if (listOfLocalesForMVP.includes(locale)) {
    return getAttractDataMVP(amount);
  }
  const apiUrl = `${attractEndpoint}/v2/calculation/hyperlink/${locale}/${amount}`;
  const res = await fetch(apiUrl);
  if (!res.ok) {
    throw Error('Could not get hyperlink from the API');
  }

  try {
    const data = await res.json();

    if (data.find((item: AttractDataResponseV2) => item.isWithinRange === false)
      && settings.hideLink) {
      return null;
    } return data as AttractDataResponseV2;
  } catch (error) {
    throw new Error('Error parsing response as JSON');
  }
};

export default getAttractDataV2;
